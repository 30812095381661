import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "menu" }
const _hoisted_2 = ["onClick"]

import { watch, ref } from 'vue'
import { useRoute } from 'vue-router';

  
  const __default__ = {
    name: 'MenuCopy'
  }


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
    list: {}
  },
  emits: ['event'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;

  const route = useRoute()
  let tabIndex = ref(route.params.type)

  watch(() => route.params.type, (n, o) => {
    if(n && n != o) {
      tabIndex.value = n
    }
  })

  const emit = __emit;
  function menuClick(key: any) {
    emit('event', key)
  }

return (_ctx: any,_cache: any) => {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_MenuCopy = _resolveComponent("MenuCopy", true)!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$props.list, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (!(item as any).children || (item as any).children.length == 0)
          ? (_openBlock(), _createBlock(_component_a_menu_item, {
              key: (item as any).key
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString((item as any).title), 1)
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true),
        ((item as any).children && (item as any).children.length > 0)
          ? (_openBlock(), _createBlock(_component_a_sub_menu, {
              key: (item as any).key
            }, {
              title: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(['need', _unref(tabIndex) == (item as any).key ? 'active' : '']),
                  onClick: ($event: any) => (menuClick((item as any).key))
                }, _toDisplayString((item as any).title), 11, _hoisted_2)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_MenuCopy, {
                  list: (item as any).children
                }, null, 8, ["list"])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})