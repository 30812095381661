import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "left_tree" }

import { ref, onMounted, getCurrentInstance, computed } from 'vue'
  import { useRoute } from 'vue-router';
  import { treeEvent } from '@/types/utils'
  import MenuCopy from './MenuCopy.vue';
  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      MenuCopy
    }
  },
  __name: 'LeftTree',
  props: {
    api: {},
    type: {}
  },
  emits: ['event'],
  setup(__props: any, { emit: __emit }) {

  

  const props = __props;
  const emit = __emit;


  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const route = useRoute();

  let chooseIndex = computed(() => {
      return [parseInt((route.params as any).type)]
  });
  let list = ref([])

  onMounted(() => {
    showPage();
  })

  function showPage() {
    globalProperties.$API.ArticleTypeList({
      parentClassification: route.meta.type
    }).then((res: any) => {
      if(res.data.code == 200) {
        let ids:[] = [] as any;
        let data = res.data.data.filter((item:any) => { return item.parentId }) as any
        data.forEach((item: any) => { (ids as any).push(item.parentId) });
        ids:[] = Array.from(new Set(ids));
        (list as any).value = treeEvent(res.data.data);
      }
    }) 
  }

  function menuClick(key: any) {
    emit('event', key)
  }

return (_ctx: any,_cache: any) => {
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_menu, {
      modelValue: _unref(chooseIndex),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(chooseIndex) ? (chooseIndex).value = $event : chooseIndex = $event)),
      onMenuItemClick: menuClick,
      "auto-open": true,
      "default-open-keys": _unref(chooseIndex),
      "default-selected-keys": _unref(chooseIndex)
    }, {
      default: _withCtx(() => [
        _createVNode(MenuCopy, {
          list: _unref(list),
          onEvent: menuClick
        }, null, 8, ["list"])
      ]),
      _: 1
    }, 8, ["modelValue", "default-open-keys", "default-selected-keys"])
  ]))
}
}

})