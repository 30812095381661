import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, isRef as _isRef, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "articles" }
const _hoisted_2 = {
  key: 1,
  class: "right"
}

import CommonTable from '@/components/CommonTable.vue';
import CommonPagination from '@/components/CommonPagination.vue';
import LeftTree from '@/components/LeftTree.vue';
import { onMounted, ref, getCurrentInstance, watch } from 'vue'
import { CONST } from '@/types/const'
import { useRoute, useRouter } from 'vue-router';
import merge from 'webpack-merge'

  
export default /*@__PURE__*/_defineComponent({
  ...{
    components: {
      LeftTree,
      CommonTable,
      CommonPagination
    }
  },
  __name: 'Index',
  setup(__props) {



  let tableData = ref([])
  let pageNum = ref(1)
  let pageSize = ref(15)
  let totalNum = ref(100)
  let show = ref(true)

  const { appContext: { config : { globalProperties } } } = (getCurrentInstance() as any);
  const route = useRoute()
  const router = useRouter()

  watch(() => route.params.type, (n, o) => {
    if(n && n != o || !n) {
      pageNum.value = 1;
      showPage()
    }
  })

  watch(() => route.name, (n, o) => {
    if(n && n != o) {
      show.value = false;
      let time = setTimeout(() => {
        clearTimeout(time);
        show.value = true;
      }, 200)
      pageNum.value = 1;
      showPage();
    }
  }, {
    immediate: true
  })

  onMounted(() => {
    showPage()
  })

  function showPage() {
    globalProperties.$API.ArticleList({
      parentClassification: parseInt((route.meta as any).type),
      articleClassificationId: parseInt((route.params as any).type),
      pageSize: pageSize.value,
      pageNum: pageNum.value,
      state: 1,
    }).then((res: any) => {
      if(res.data.code == 200) {
        tableData.value = res.data.data.records;
        totalNum.value = res.data.data.total  
      }
    })
  }

  function eventClick(id: number) {
    router.replace({
      params:merge(route.params, {'type': id })
    })
  }


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(show))
      ? (_openBlock(), _createBlock(LeftTree, {
          key: 0,
          api: (_ctx.$route.meta as any).api,
          type: (_ctx.$route.meta as any).type,
          onEvent: eventClick
        }, null, 8, ["api", "type"]))
      : _createCommentVNode("", true),
    (_unref(show))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(CommonTable, {
            columns: (_unref(CONST) as any).articleTable,
            tableData: _unref(tableData)
          }, null, 8, ["columns", "tableData"]),
          _createVNode(CommonPagination, {
            pageNum: _unref(pageNum),
            pageSize: _unref(pageSize),
            totalNum: _unref(totalNum),
            onPageChange: _cache[0] || (_cache[0] = (page) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = page : pageNum = page; showPage() }),
            onSizeChange: _cache[1] || (_cache[1] = (size) => { _isRef(pageNum) //@ts-ignore
 ? pageNum.value = 1 : pageNum = 1; _isRef(pageSize) //@ts-ignore
 ? pageSize.value = size : pageSize = size; showPage() })
          }, {
            left: _withCtx(() => [
              _createTextVNode(" 共" + _toDisplayString(_unref(totalNum)) + "条，每页" + _toDisplayString(_unref(pageSize)) + "条", 1)
            ]),
            _: 1
          }, 8, ["pageNum", "pageSize", "totalNum"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})